<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="my-1"
							>
								<b-alert
									variant="info"
									show
								>
									<h6 class="alert-heading">
										<feather-icon
											icon="InfoIcon"
											class="mr-50"
										/>
										<span
											class="small"
										>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
									</h6>
								</b-alert>
							</b-col>

							<!-- TITLE -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="name">
									<strong>OBJETO DEL CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Objeto del contrato"
								>
									<b-form-input
										id="name"
										v-model="formData.title"
										placeholder="Ingrese el objeto del contrato ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT TYPE -->
							<b-col
								cols="12"
								md="5"
								class="mb-2"
							>
								<label for="contractType">
									<strong>TIPO DE CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Tipo de contrato"
								>
									<v-select
										id="contractType"
										v-model="formData.contract_type"
										:state="errors.length > 0 ? false : null"
										:options="options.contractTypes"
										placeholder="Seleccione"
									>
										<template #option="{ label, description, code }">
											{{ label }}
											<br />
											<cite>{{ description }} | Código: {{ code }}</cite>
										</template>
									</v-select>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- LOCATION -->
							<b-col
								cols="12"
								md="4"
								class="mb-2"
							>
								<label for="location">
									<strong>EJECUCIÓN DE SERVICIO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Ejecución de servicio"
								>
									<v-select
										id="location"
										v-model="formData.locations"
										:state="errors.length > 0 ? false : null"
										:options="options.locations"
										multiple
										placeholder="Seleccione"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="tender_type">
									<strong>TIPO DE LICITACIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Tipo de licitación"
								>
									<v-select
										id="tender_type"
										v-model="formData.tender_type"
										:state="errors.length > 0 ? false : null"
										:options="options.tenderTypes"
										placeholder="Seleccione"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- DESCRIPTION -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>DESCRIPCIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Descripción"
								>
									<b-form-textarea
										id="description"
										v-model="formData.description"
										placeholder="Ingrese los detalles ..."
										:state="(errors.length > 0 ? false:null)"
										rows="5"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CHECK VISIT -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<b-form-checkbox
									v-model="formData.enabled_visit"
									@change="setData()"
									:value="1"
								>HABILITAR VISITA</b-form-checkbox>
							</b-col>

							<!-- VISIT DOCUMENTS -->
							<b-col
								cols="12"
								v-if="formData.enabled_visit"
							>
								<b-input-group class="mb-1">
									<b-form-input
										v-model="newDocument"
										placeholder="Ingrese nuevo documento y presione ENTER o click al botón AGREGAR"
										@keydown.enter.prevent="addDocument"
										trim
									/>
									<b-input-group-append>
										<b-button
											@click="addDocument"
											variant="outline-primary"
										>Agregar</b-button>
									</b-input-group-append>
								</b-input-group>

								<label
									for="visitDocuments"
									class="mb-1"
								>
									<strong>DOCUMENTOS REQUERIDOS PARA LA VISITA</strong>
								</label>

								<div
									class="d-flex align-items-center mb-1"
									v-for="(document, index) in formData.documents"
									:key="index"
								>
									<b-form-checkbox
										name="documents"
										:checked="document.is_required === 1"
										@change="toggleRequired(document)"
									>{{ document.name }}</b-form-checkbox>
									<div
										class="d-flex align-items-center text-nowrap"
										v-if="document.is_new"
									>
										<feather-icon
											:id="`document-${index}`"
											icon="XIcon"
											class="cursor-pointer text-danger"
											style="margin-left: 10px"
											size="16"
											@click.stop="removeDocument(index)"
										/>
										<b-tooltip
											variant="danger"
											placement="right"
											triggers="hover"
											title="Eliminar"
											class="cursor-pointer"
											:target="`document-${index}`"
										/>
									</div>
								</div>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<b-button
									v-if="!processing"
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="button"
									variant="outline-secondary"
									class="order-sm-1 order-2 mt-2 mt-sm-0"
									:to="{ name: 'requests-list' }"
								>CANCELAR</b-button>

								<!-- BUTTON SUBMIT -->
								<b-button-loading
									text="GUARDAR Y CONTINUAR"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
								/>
							</b-col>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email, integer, min } from "@validations"
import requestStoreModule from "../../requestStoreModule"
import useNotifications from "@notifications"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import axios from "@axios"
import store from "@/store"
import router from "@/router"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		required,
		email,
		integer,
		min,
		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const processing = ref(false)
		const options = ref({})
		const newDocument = ref(null)
		const selectedDocuments = ref([])
		const blankData = {
			title: null,
			description: null,
			contract_type: null,
			tender_type: null,
			locations: [],
			enabled_visit: 0,
			documents: []
		}
		const formData = ref(blankData)

		const REQUEST_APP_STORE_MODULE_NAME = "app-request"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(REQUEST_APP_STORE_MODULE_NAME, requestStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(async () => {
			await getSelectFilters()
			setData()
		})

		const getSelectFilters = async () => {
			try {
				const response1 = await axios.get("/selectors/contract-types")
				const response2 = await axios.get("/selectors/locations")
				const response3 = await axios.get("/documents?is_visit=1")
				const tenderTypes = [
					{ label: "NORMAL", value: "NORMAL" },
					{ label: "DIRECTA", value: "DIRECTA" }
				]

				options.value = {
					...response1.data,
					...response2.data,
					...response3.data,
					tenderTypes
				}
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const setData = () => {
			const documents = options.value.documents.map((item) => ({
				name: item.name,
				is_required: 0,
				is_new: 0
			}))

			formData.value.documents = [...documents]
		}

		const resetFormData = () => {
			formData.value = blankData
		}

		const addDocument = () => {
			if (newDocument.value) {
				formData.value.documents.push({
					name: newDocument.value.toUpperCase(),
					is_required: 1,
					is_new: 1
				})
			}

			newDocument.value = null
		}

		const removeDocument = (index) => {
			formData.value.documents.splice(index, 1)
		}

		const toggleRequired = (document) => {
			document.is_required = document.is_required === 1 ? 0 : 1
		}

		const onSubmit = async () => {
			processing.value = true

			const isValid = await refForm.value.validate()

			if (!isValid) {
				toastNotification("Complete los campos requeridos", false)
				return
			}
			if (!formData.value.locations.length) {
				toastNotification(
					"Debe seleccionar al menos un lugar de ejecuición de servicio",
					false
				)
				return
			}
			if (formData.value.enabled_visit) {
				const haveDocsRequired = formData.value.documents.some(
					(item) => item.is_required === 1
				)
				if (!haveDocsRequired) {
					toastNotification(
						"Debe seleccionar al menos un documento para la visita",
						false
					)
					return
				}
			}

			const locations = formData.value.locations.map((item) => item.value)

			const payload = {
				locations,
				title: formData.value.title,
				description: formData.value.description,
				contract_type_id: formData.value.contract_type.value,
				tender_type: formData.value.tender_type.value,
				enabled_visit: formData.value.enabled_visit,
				selectedDocuments: formData.value.documents
			}

			store
				.dispatch("app-request/createRequest", payload)
				.then((response) => {
					resetFormData()
					refForm.value.reset()
					toastNotification(response.data.message)
					router.push({
						name: "requests-edit",
						params: { id: response.data.id },
						query: { tab: "1" }
					})
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error al crear solicitud"
					toastNotification(message, false)
				})
				.finally(() => {
					processing.value = false
				})
		}

		return {
			// DATA
			processing,
			formData,
			options,

			// REFS
			refForm,
			newDocument,
			selectedDocuments,

			// METHODS
			setData,
			addDocument,
			removeDocument,
			toggleRequired,
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
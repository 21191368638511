
<template>
	<component :is="'div'">
		<b-tabs pills>
			<!-- Tab: General Information -->
			<b-tab name="info">
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<tender-store-tab-general-information class="mt-2 pt-75" />
			</b-tab>

			<!-- Tab: Bidders -->
			<b-tab
				name="bidders"
				disabled
			>
				<template #title>
					<feather-icon
						icon="LockIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Postores</span>
				</template>
			</b-tab>

			<!-- Tab: Validations -->
			<b-tab
				name="validations"
				disabled
			>
				<template #title>
					<feather-icon
						icon="LockIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Bases</span>
				</template>
			</b-tab>

			<!-- Tab: Approvals -->
			<b-tab
				name="approvals"
				disabled
			>
				<template #title>
					<feather-icon
						icon="LockIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Aprobaciones</span>
				</template>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import TenderStoreTabGeneralInformation from "./general-information/TenderTabGeneralInformation.vue"

export default {
	components: {
		TenderStoreTabGeneralInformation
	}
}
</script>